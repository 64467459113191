@font-face {
  font-family: "charlevoix";
  src: url("./fonts/CharlevoixPro-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "charlevoix";
  src: url("./fonts/CharlevoixPro-Bold.otf");
  font-weight: bold;
}

#root{
  display: flex;
  width: 100%;
  min-height: 100vh;
}

body{
  background-color:  #0D0D0D;
  color: #fff;
  font-family: "charlevoix";
  display: flex;
  margin: 0;
  width: 100%;
  height: 100vh;
}

.show{
  width: 50%;
  background-image: url("./img/profilpicsaturated.png");
  background-position: center;
  background-size: cover;
}

@media screen and (orientation: portrait){
  .show{
      width: 100%;
      min-height: 100vh;
  }
}

img {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  object-fit: cover;
}